import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    OverlayTrigger,
    Table,
    Tooltip
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import {
    withAuthenticatedUserContext
} from "../../context/AuthenticatedUserContext";
import Page404 from "../Page404";
import Helmet from "../../components/Helmet";
import TagPill from "../../components/tagPill";
import OverviewSortTableHeader from "../../components/overview/OverviewSortTableHeader";
import Loading from "../../components/Loading";
import VATRefundRow from "./components/VATRefundRow";

class VATRefundOverview extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            vatRefunds: null,
            searchQuery: "",
            lastSortKey: "",
            error: null
        };
        this.onSearch = this.onSearch.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onSort = this.onSort.bind(this);
    }

    componentDidMount() {
        this.getVATRefunds();

        if(window.location.search.startsWith("?search=")) {
            const searchString = decodeURI(window.location.search.split("=")[1]);
            this.setState({ searchQuery: searchString });
            this.setSearchQuery(searchString);
            this.props.history.push(window.location.pathname)
        } else {
            this.setState({ searchQuery: this.getSearchQuery() });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.params.pageType !== this.props.match.params.pageType) {
            this.getVATRefunds();
        }
    }

    getLocalStoragePrefix() {
        return "vatRefunds";
    }

    getSearchQuery() {
        return localStorage.getItem(this.getLocalStoragePrefix() + "SearchQuery") || "";
    }

    setSearchQuery(value) {
        localStorage.setItem(this.getLocalStoragePrefix() + "SearchQuery", value);
    }

    getSortKey() {
        return localStorage.getItem(this.getLocalStoragePrefix() + "SortKey") || "date";
    }

    setSortKey(value) {
        localStorage.setItem(this.getLocalStoragePrefix() + "SortKey", value);
    }

    getSortAscending() {
        return (localStorage.getItem(this.getLocalStoragePrefix() + "SortAscending") || "1") === "1";
    }

    setSortAscending(value) {
        localStorage.setItem(this.getLocalStoragePrefix() + "SortAscending", value ? "1" : "0");
    }

    onSearch(event) {
        this.setState({ searchQuery: event.target.value });
        this.setSearchQuery(event.target.value);
    }

    sort(vatRefunds, key, ascending = true) {
        this.setState({ lastSortKey: !ascending ? "p" + key : key });
        this.setSortKey(key);
        this.setSortAscending(ascending);
        if(!vatRefunds) {
            return vatRefunds;
        }
        return vatRefunds.sort(((a, b) => {
            let aValue = a[key] && (typeof a[key] === "object" ? ("name" in a ? a[key]['name'] : a[key]['id']) : a[key]);
            aValue = typeof aValue === 'string'? aValue.toLowerCase() : aValue;
            let bValue = b[key] && (typeof b[key] === "object" ? ("name" in b ? b[key]['name'] : b[key]['id']) : b[key]);
            bValue = typeof bValue === 'string'? bValue.toLowerCase() : bValue;

            if(aValue && bValue) {
                if(aValue < bValue) {
                    return ascending ? 1 : -1;
                } else if(aValue > bValue) {
                    return ascending ? -1 : 1;
                }
            } else if (!aValue && bValue) {
                return -1;
            } else if (aValue && !bValue) {
                return 1;
            } else if(a.id < b.id) {
                return ascending? 1 : -1;
            } else {
                return ascending? -1 : 1;
            }
            return 0;
        }))
    }

    getVATRefunds() {
        this.setState({ vatRefunds: null, error: null });
        axios.post("/getVATRefunds", { filter: this.getPageType() })
            .then((response) => {
                if(response.data.valid) {
                    if(response.data.page !== undefined && response.data.page !== this.getPageType()) {
                        return;
                    }
                    this.setState({ vatRefunds: this.sort(response.data.data, this.getSortKey(), this.getSortAscending()) });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                console.error(error);
            });
    }

    onSort(key = "date", ascending = null) {
        if(typeof ascending !== 'boolean') {
            ascending = this.state.lastSortKey !== key;
        }
        this.setState((state) => {
            return {
                vatRefunds: this.sort(state.vatRefunds, key, ascending)
            }
        });
    }

    onReset() {
        this.setState({ searchQuery: "" });
        this.setSearchQuery("");
        this.onSort("date", true);
    }

    getPageTypeTitle() {
        switch(this.getPageType()) {
            case "all":
                return "Alle BTW Terugvragen";
            default:
            case "open":
                return "Openstaande BTW Terugvragen";
            case "finished":
                return "Afgeronde BTW Terugvragen";
        }
    }

    getPageType() {
        switch(this.props.match.params.pageType) {
            case "all":
                return "all";
            default:
            case "open":
                return "open";
            case "finished":
                return "finished";
        }
    }

    render() {
        if(this.getPageType() === null) {
            return (
                <Page404/>
            )
        }
        return (
            <React.Fragment>
                <Helmet title={ this.getPageTypeTitle() }/>

                <Title preTitle="Overzicht" noBottom>
                    { this.getPageTypeTitle() }
                    <small className="ml-2">
                        <TagPill>
                            { this.state.vatRefunds ? this.state.vatRefunds.length : 0 }
                        </TagPill>
                    </small>
                </Title>

                <TabBar noBottom>
                    <TabBarItem to="/vat-refunds">
                        Openstaand
                    </TabBarItem>
                    <TabBarItem to="/vat-refunds/finished">
                        Afgerond
                    </TabBarItem>
                    <TabBarItem to="/vat-refunds/all">
                        Alle
                    </TabBarItem>

                    <div className="ml-auto align-self-center">
                        <div className="input-group float-right" style={{maxWidth: 300}}>
                            <input type="search" className="search form-control"
                                   placeholder="Zoeken" value={this.state.searchQuery} onChange={ this.onSearch } />
                            <div className="input-group-append">
                                <OverlayTrigger overlay={
                                    <Tooltip id="reset">Reset</Tooltip>
                                }>
                                    <button className="btn btn-secondary" onClick={ this.onReset }>
                                        <i className="fas fa-undo"/>
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                </TabBar>

                <span className="counter float-right"></span>

                <Table hover striped size="sm" className="results">
                    <thead className="thead-light">
                        <tr className="tr-sticky">
                            <OverviewSortTableHeader
                                title="#"
                                sortKey="id"
                                className="text-center"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.onSort }
                                minWidth="60px"
                            />
                            <OverviewSortTableHeader
                                title="Status"
                                sortKey="status"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.onSort }
                            />
                            <OverviewSortTableHeader
                                title="Naam"
                                sortKey="name"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.onSort }
                            />
                            { this.props.authenticatedUserContext.user.vatRefund && (
                                <OverviewSortTableHeader
                                    title="BSN"
                                    sortKey="bsn"
                                    className="text-left"
                                    currentSortKey={ this.state.lastSortKey }
                                    sortFunction={ this.onSort }
                                />
                            )}
                            <OverviewSortTableHeader
                                title="Adres"
                                sortKey="street"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.onSort }
                            />
                            <OverviewSortTableHeader
                                title="Factuurdatum"
                                sortKey="invoiceDate"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.onSort }
                                minWidth="150px"
                            />
                            <OverviewSortTableHeader
                                title="Datum"
                                sortKey="date"
                                className="text-left"
                                currentSortKey={ this.state.lastSortKey }
                                sortFunction={ this.onSort }
                                minWidth="160px"
                            />
                        </tr>
                    </thead>

                    <tbody>
                        { this.state.error ? (
                            <tr className="loading-row">
                                <td colSpan={ 8 }>
                                    <Alert variant="danger">{ this.state.error }</Alert>
                                </td>
                            </tr>
                        ) : this.state.vatRefunds == null ? (
                            <tr className="loading-row">
                                <td colSpan={ 8 } className="py-4 text-center">
                                    <Loading/>
                                </td>
                            </tr>
                        ) : this.state.vatRefunds.length <= 0 ? (
                            <tr className="loading-row">
                                <td colSpan={ 8 } className="text-center">
                                    <h1><i className="fas fa-box-open"/></h1>
                                    <h3>Geen BTW Terugvragen</h3>
                                    <p>Er zijn geen BTW Terugvragen gevonden voor de huidige filter.</p>
                                    { this.getPageType() === "open" && (
                                        <Link to="/vat-refunds/add" className="btn btn-primary btn-sm">
                                            <i className="fas fa-plus mr-2"/>
                                            Nieuwe BTW Terugvraag
                                        </Link>
                                    )}
                                </td>
                            </tr>
                        ) : this.state.vatRefunds.map(vatRefund => (
                            <VATRefundRow
                                key={ vatRefund.id }
                                vatRefund={ vatRefund }
                                pageType={ this.getPageType() }
                                searchQuery={ this.state.searchQuery }
                                to={ "/vat-refund/" + vatRefund.id }
                            />
                        ))}
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }


}

export default withAuthenticatedUserContext(VATRefundOverview);
