import axios from "axios";

import ReminderBaseModal from "./ReminderBaseModal";
import {
    withIntranetSidebarContext
} from "../../../components/sidebar/IntranetSidebarContext";

class ReminderNewModal extends ReminderBaseModal {
    getModalTitle() {
        return "Herinnering toevoegen";
    }

    resetState() {
        this.setState({
            error: null,
            users: null,
            assignedUserId: this.props.defaultAssignedUserId,
            date: null,
            message: null
        });
    }

    save() {
        this.addReminder();
    }

    addReminder() {
        if(this.state.date === null || this.state.date.length === 0 || this.state.message === null || this.state.message.length === 0) {
            this.setState({ error: "Niet alle velden zijn ingevuld." });
            return;
        }
        this.setState({ loading: true });
        axios.post("/addReminder", {
            leadId: this.props.leadId,
            outageId: this.props.outageId,
            installationId: this.props.installationId,
            vatRefundId: this.props.vatRefundId,
            assignedUserId: this.state.assignedUserId,
            date: this.state.date,
            message: this.state.message
        })
            .then((response) => {
                if(response.data.valid) {
                    if(this.props.reminderAdded) {
                        this.props.reminderAdded();
                    }
                    this.props.intranetSidebarContext.refreshReminders();
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }
}

export default withIntranetSidebarContext(ReminderNewModal);
