import React from "react";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";
import {
    Annotation
} from "@zandor300/react-apple-mapkitjs";

import AppleMapsMap from "./AppleMapsMap";

import glyph from "../../img/solar-glyph.svg";

function AppleMapsModal(props) {
    return (
        <Modal size="fullscreen" show={ props.show } onHide={ props.handleClose }>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className="fab fa-apple ml-2 mr-3"/>
                    Apple Maps
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                { (!props.latitude || !props.longitude) ? (
                    <div className="p-3 h-100">
                        { props.refreshCoordinatesError && (
                            <Alert variant="danger">{ props.refreshCoordinatesError }</Alert>
                        )}
                        <div className="d-flex align-items-center w-100 h-100">
                            <div className="w-100 text-center pb-5">
                                <h1><i className="fas fa-map-marker-times"/></h1>
                                <h3>Coördinaten niet beschikbaar</h3>
                                <p>{ props.emptyState }</p>
                                <div className="btn-toolbar d-block mx-auto">
                                    { props.refreshCoordinates && (
                                        <div className="btn-group mr-2">
                                            <Button variant="primary" onClick={ props.refreshCoordinates }>
                                                Coördinaten bijwerken
                                            </Button>
                                        </div>
                                    )}
                                    <div className="btn-group">
                                        <Button variant="secondary" onClick={ props.handleClose }>
                                            Sluiten
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <AppleMapsMap
                        longitude={ props.longitude }
                        latitude={ props.latitude }
                        height="100%"
                        width="100%"
                        zoomLevel={ 8 }
                        initialMapType="hybrid"
                    >
                        <Annotation
                            longitude={ props.longitude }
                            latitude={ props.latitude }
                            color="#FF5000"
                            glyphImage={ glyph }
                            title={ props.title }
                        />
                    </AppleMapsMap>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default React.memo(AppleMapsModal);
