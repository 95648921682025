import React from "react";
import {
    Link
} from "react-router-dom";

import {
    withAuthenticatedUserContext
} from "../../../context/AuthenticatedUserContext";
import TagPill from "../../../components/tagPill";
import DateFormatter from "../../../components/formatters/DateFormatter";
import initialsFormatter from "../../../components/formatters/InitialsFormatter";

class VATRefundRow extends React.PureComponent {
    searchActive() {
        const vatRefund = this.props.vatRefund;
        const user = this.props.authenticatedUserContext.user;
        const name = !vatRefund ? "" : vatRefund.initials + " " + vatRefund.lastName;
        if(this.props.searchQuery) {
            const splitSearchQuery = this.props.searchQuery.split(" ");
            // Loop through every search query item
            for(let searchQueryIndex = 0; searchQueryIndex < splitSearchQuery.length; searchQueryIndex++) {
                const searchQuery = (splitSearchQuery[searchQueryIndex] || "").toLowerCase();
                if(searchQuery.indexOf("=") !== -1) {
                    let [searchKey, searchValue] = searchQuery.split("=");
                    switch(searchKey) {
                        default:
                        case "id":
                            if(parseInt(vatRefund.id) !== parseInt(searchValue))
                                return false;
                            break;
                        case "phonenumber":
                        case "telefoonnummer":
                        case "telefoon":
                            if(vatRefund.phoneNumber.replace(/\s/g,"").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "email":
                            if(vatRefund.email.replace(/\s/g,"").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "status":
                            if(parseInt(vatRefund.status.id) !== parseInt(searchValue)
                                && vatRefund.status.name.replace(/\s/g,"").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "name":
                        case "naam":
                            if(name.replace(/\s/g,"").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "address":
                        case "adres":
                            if((vatRefund.street + vatRefund.houseNumber + "," + vatRefund.city).replace(/\s/g,"").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "bsn":
                            if(!user.vatRefund) {
                                break;
                            }
                            if(vatRefund.bsn.indexOf(searchValue) === -1)
                                return false;
                            break;
                    }
                }
                else {
                    // Installation Id
                    if(parseInt(vatRefund.id) === parseInt(searchQuery))
                        continue;

                    // Phone number
                    if(vatRefund.phoneNumber.replace(/\s/g,"").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Email
                    if(vatRefund.email.replace(/\s/g,"").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Status
                    if(parseInt(vatRefund.status.id) === parseInt(searchQuery)
                        || vatRefund.status.name.replace(/\s/g,"").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Name
                    if(name.replace(/\s/g,"").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Address
                    if((vatRefund.street + vatRefund.houseNumber + "," + vatRefund.city).replace(/\s/g,"").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    if(user.vatRefund) {
                        // BSN
                        if(vatRefund.bsn.indexOf(searchQuery) !== -1)
                            continue;
                    }

                    // Wanings
                    if(vatRefund.warnings && vatRefund.warnings.length > 0
                        && ("warning".indexOf(searchQuery) !== -1 || "waarschuwing".indexOf(searchQuery) !== -1))
                        continue;

                    return false;
                }
            }
        }
        return true;
    }

    render() {
        if(!this.searchActive()) {
            return null;
        }

        const vatRefund = this.props.vatRefund;
        const url = this.props.to;
        const user = this.props.authenticatedUserContext.user;

        let classNames = ["table-clickable-row"];
        if(vatRefund.warnings && vatRefund.warnings.length > 0) {
            classNames.push("table-warning");
        }

        return (
            <tr className={ classNames.join(" ") }>
                <th scope="row" className="align-middle text-center">
                    <Link to={ url }>
                        { vatRefund.id }
                    </Link>
                </th>
                <td className="align-middle">
                    <Link to={ url }>
                        <TagPill color={ vatRefund.status.color }>
                            { vatRefund.status.name }
                        </TagPill>
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ url }>
                        { initialsFormatter({ initials: vatRefund.initials }) + " " + vatRefund.lastName }
                    </Link>
                </td>
                { user.vatRefund && (
                    <td className="align-middle">
                        <Link to={ url }>
                            { vatRefund.bsn }
                        </Link>
                    </td>
                )}
                <td className="align-middle">
                    <Link to={ url }>
                        { vatRefund.street + " " + vatRefund.houseNumber + ", " + vatRefund.city }
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ url }>
                        { !vatRefund.invoiceDate ? (
                            <i className="text-muted">Leeg</i>
                        ) : (
                            <DateFormatter
                                date={ vatRefund.invoiceDate }
                                format="DD-MM-YYYY"
                            />
                        )}
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ url }>
                        <DateFormatter
                            date={ vatRefund.date }
                            format="DD-MM-YYYY HH:mm"
                        />
                    </Link>
                </td>
            </tr>
        )
    }
}

export default withAuthenticatedUserContext(VATRefundRow);
